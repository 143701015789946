<template>
  <v-row>
    <v-col cols="12" lg="8" xl="8" class="background-login d-none d-md-flex align-center justify-center px-0 py-0">
      <img class="img-cnt-login" src="@/assets/images/gquest.png" alt="image" />
    </v-col>
    <v-col cols="12" lg="4" xl="4" class="d-flex align-center">
      <v-container>
        <div class="">
          <v-row class="justify-center">
            <v-col cols="10" lg="10" xl="10">
              <div style="text-align:center">
                <div style="max-height:100px !important" class="mt-6">
                  <img width="100" src="@/assets/images/gmo_gquest.svg" />
                </div>
              </div>
              <v-form ref="form" v-model="valid" lazy-validation action="/" @submit.prevent="submit">
                <v-text-field
                  v-model="usuario"
                  :rules="usuarioRules"
                  label="Usuario"
                  class="mt-4"
                  required
                  outlined
                  uppercase
                  prepend-inner-icon="fas fa-id-badge"
                  @blur="upperCase"
                >
                </v-text-field>
                <v-text-field
                  v-model="clave"
                  :rules="claveRules"
                  label="Contraseña"
                  required
                  outlined
                  :type="show1 ? 'text' : 'password'"
                  :append-icon="show1 ? 'fas fa-eye' : 'fas fa-eye-slash'"
                  @click:append="show1 = !show1"
                ></v-text-field>
                <!--                <v-select-->
                <!--                  :items="data"-->
                <!--                  v-model="empresa"-->
                <!--                  :rules="empresaRules"-->
                <!--                  label="Empresa"-->
                <!--                  outlined-->
                <!--                  required-->
                <!--                  prepend-inner-icon="fas fa-building"-->
                <!--                ></v-select>-->
                <v-btn
                  :loading="loading"
                  :disabled="!valid || loading"
                  color="info"
                  block
                  class="mr-4"
                  submit
                  type="submit"
                  >Iniciar Sesión</v-btn
                >
              </v-form>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import LoginService from '../services/LoginService';
import Swal from 'sweetalert2';
import { decryptAES, decryptData, encryptAES } from '@/components4x/utils/utils4x';
export default {
  name: 'Login',
  data: () => ({
    loading: false,
    loginService: null,
    valid: true,
    clave: '',
    empresa: '',
    show1: false,
    usuario: '',
    claveRules: [(v) => !!v || 'Contraseña es requerida.'],
    usuarioRules: [(v) => !!v || 'Usuario es requerido.'],
    empresaRules: [(v) => !!v || 'Empresa es requerida'],
    checkbox: false,
    data: []
  }),
  methods: {
    upperCase() {
      this.usuario = this.usuario.toUpperCase();
    },
    async iniciaData() {
      this.data.push({ text: 'AGROVISION', value: '001' });
      this.data.push({ text: 'ARENAVERDE', value: '002' });
      // const res = await this.loginService.get().execResource('empresas', {
      //   api_token: 'BBBBBBBBBBB*cXXVOkdQgiJBJBBCKF53UZkX'
      // });
      // res.forEach((element) => {
      //   this.data.push({ text: element.razonsocial, value: element.idempresa });
      // });
    },
    async submit() {
      this.$refs.form.validate();
      if (this.$refs.form.validate(true)) {
        this.loading = true;
        const res = await this.loginService.post().execResource('login', {
          username: this.usuario,
          password: this.clave
        });

        if (!res.status) {
          await Swal.fire({
            icon: 'error',
            title: res.message,
            confirmButtonText: `OK`
          });
          this.loading = false;
          return;
        }
        if (parseInt(res.data.user.dias) <= 15) {
          if (res.data.user.dias <= 0) {
            await Swal.fire({
              icon: 'error',
              title: `Su licencia ha vencido, no cuenta con acceso al sistema...`,
              confirmButtonText: `OK`
            });
            this.loading = false;
            return;
          }
          await Swal.fire({
            icon: 'warning',
            title: `Quedan ${res.data.user.dias} dias de prueba...`,
            confirmButtonText: `OK`
          });
        }
        console.log(res);

        await this.setLocal(res);

        let decrypt = decryptData(res.data.user.module, true);
        await this.$store.dispatch('updatePermission', decrypt);

        this.loading = false;
        await this.$router.push({ path: '/inicio' });
      }
    },
    async setLocal(res) {
      localStorage.setItem('led17h9p2x', res.data.token);
      //localStorage.setItem('pwm90k2i6x', encryptAES(this.empresa)); // empresa
      localStorage.setItem('ntt04h4b8b', encryptAES(this.usuario)); // usuario
      localStorage.setItem('vwa85y4d6d', encryptAES(this.clave)); // contraseña
      localStorage.setItem('pwm90k2i6x', encryptAES(res.data.user.business_name)); // nombre compañia
      localStorage.setItem('rte459h6v5s', encryptAES(res.data.user.eshtec)); // eshtec
      localStorage.setItem('xdw345d7g1a', encryptAES(res.data.user.esadmin)); // esadmin

      console.log(decryptAES(localStorage.getItem('rte459h6v5s')));
      console.log(decryptAES(localStorage.getItem('xdw345d7g1a')));
      //-----------------------------------------------

      localStorage.setItem('nc4rhtcc976', res.data.user.company_id); // id de compañia
      localStorage.setItem('k3rtxqdp234', res.data.user.full_name); // nombre completo
      localStorage.setItem('oq1rcizm635', res.data.user.module); // modulo
      //localStorage.setItem('iow5csjr398', res.data.user.process_id); // id del proceso
      //localStorage.setItem('ppw3kkll432', res.data.user.process_name); // nombre del proceso
      localStorage.setItem('ppw3kkll432', res.data.user.uid); // uid
    }
  },
  async created() {
    if (localStorage.getItem('led17h9p2x') !== null) {
      await this.$router.push({ path: '/inicio' });
    }
    this.empresa = decryptAES(localStorage.getItem('pwm90k2i6x'));
    this.usuario = decryptAES(localStorage.getItem('ntt04h4b8b'));
    this.clave = decryptAES(localStorage.getItem('vwa85y4d6d'));
  },
  async mounted() {
    this.loginService = this.$httpService(new LoginService(), this);
    await this.iniciaData();
  }
};
</script>
<style lang="css">
.img-cnt-login {
  width: 100%;
  height: 100vh;
}
</style>
