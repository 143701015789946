class LoginService {
  constructor() {
    // this.token = 'tokenjob';
  }
  resources = () => ({
    login: {
      uri: `/v1/login`,
      method: ['post']
    },
    empresas: {
      uri: `/ext/empresas`,
      method: ['get'],
      auth: {
        username: 'HTECUSER',
        password: 'htecurser*_*2020giplan'
      }
    }
  });
}

export default LoginService;
